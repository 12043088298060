<template>
  <teleport to="#modals">
    <div v-if="existModal"
         class="modal"
         :class="{'modal-become-visible': visibleModal}"
         :style="{'z-index': 2000 + 100*openIndex }"
         @click.capture="blockEventsOnClosingModal"
    >
      <div :class="'modal-content '+(className ? className : '')"
           :style="{width: width}"
           @click="(e) => e.stopPropagation()"
      >
        <slot></slot>
      </div>
    </div>
  </teleport>
</template>

<script setup>
import {onMounted, onUnmounted, ref, watch} from "vue";
import {useAppStore} from "Common/stores/appStore"

const props = defineProps({
  visible: Boolean,
  className: {
    type: String,
    required: false
  },
  width: {
    type: String,
    required: false
  },
  close: {
    type: Function,
    required: false
  },
})

const appStore = useAppStore();

const existModal = ref(false);
const visibleModal = ref(false);

const existTimeout = ref(null); //for fast double opening the same modal

const openIndex = ref(0); //for correct z-index when many modals was opened

watch(() => props.visible, () => {
  if(props.visible) {
    openIndex.value = appStore.onNewModalOpened();
    existModal.value = true;
    existTimeout.value = null; //prevent "else timeout"
    setTimeout(() => visibleModal.value = true, 1);
  }
  else {
    appStore.onModalClosed(openIndex.value);
    visibleModal.value = false;
    existTimeout.value = setTimeout(() => {
      if(existTimeout.value){
        existModal.value = false;
        existTimeout.value = null;
      }
    }, 500); //should be the same as #search000002
  }
}, {immediate: true})


onMounted(() => {
  document.addEventListener('keydown', onKeyDown);
})

onUnmounted(() => {
  appStore.onModalClosed(openIndex.value);
  document.removeEventListener('keydown', onKeyDown);
})

function onKeyDown (event){
  const key = event.key; // const {key} = event; in ES6+
  if (key === "Escape") {
    onClose();
  }
}

function onClose (event){
  if(props.close){
    props.close(event);
  }
}

function blockEventsOnClosingModal (event){ //block events when animation of closing window is playing
  if(!visibleModal.value){
    event.stopPropagation();
  }
}

</script>

<style lang="scss" src="Common/assets/styles/modal.scss"></style>
