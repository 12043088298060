import { createWebHistory } from "vue-router";
import {ROUTES, updateSiteReportBreadcrumbs} from "@/utility/routeUtils";
import {lazyLoading} from "Common/utility/component";

const Login = lazyLoading(() => import('@/pages/login/login'))
const Unsubscribe = lazyLoading(() => import('@/pages/unsubscribe/unsubscribe'))
const ShortLink = lazyLoading(() => import('@/pages/shortLink'))
const Login2FA = lazyLoading(() => import('@/pages/login/2fa/Layout2FA'))
const Verify = lazyLoading(() => import('@/pages/login/verify'))
const CreatePassword = lazyLoading(() => import('@/pages/login/create-password'))
const EmailConfirmation = lazyLoading(() => import('@/pages/login/email-confirmation'))
const OrgOnboardingSteps = lazyLoading(() => import('@/pages/login/onboarding-steps.vue'))
const Register = lazyLoading(() => import("@/pages/login/register.vue"))
const ResetPassword = lazyLoading(() => import('@/pages/login/reset-password'))
const ForgotPassword = lazyLoading(() => import('@/pages/login/forgot-password'))
const ReactivateAccount = lazyLoading(() => import('@/components/reactivate_account'))
const PaymentSuccess = lazyLoading(() => import('@/components/PaymentSuccess'))
const PaymentError = lazyLoading(() => import('@/components/PaymentError'))
const CustomerSupportCasesRoot = lazyLoading(() => import("@/pages/customer-support/Index"))
const CustomerSupportCases = lazyLoading(() => import("@/pages/customer-support/Cases"))
const CustomerSupportCaseManage = lazyLoading(() => import("@/pages/customer-support/CaseManage"))

const CustomerSupportCasesManagementRoot = lazyLoading(() => import("@/pages/customer-support-managment/Index"))
const CustomerSupportCasesManagement = lazyLoading(() => import("@/pages/customer-support-managment/Cases"))
const CustomerSupportCaseManageManagement = lazyLoading(() => import("@/pages/customer-support-managment/CaseManage"))

const SettingsUsers = lazyLoading(() => import('@/components/users_container'))
const AnalyzeNowAPIKeys = lazyLoading(() => import('@/pages/app/account/userApiKeys/api_keys'))
const AccountSecurity = lazyLoading(() => import('@/pages/app/account/security/security'))
const SiteDetails = lazyLoading(() => import('@/components/site_details'))
const DocumentationPage = lazyLoading(() => import('@/components/DocumentationPage'))
const ApiKeyDetails = lazyLoading(() => import('@/components/api_keys_details'))
const DockerDetails = lazyLoading(() => import('@/components/docker_details.vue'))
const CloudAnalysis = lazyLoading(() => import('@/pages/app/reports/cloud_analysis'))
const DockerErrors = lazyLoading(() => import('@/pages/app/reports/docker_errors'))
const SettingsNotifications = lazyLoading(() => import('@/components/notifications'))
const SettingsSecurity = lazyLoading(() => import('@/components/security'))

const Dashboard = lazyLoading(() => import('@/pages/app/dashboard/dashboard'))
const Projects = lazyLoading(() => import('@/pages/app/projects/projects'))
const Onboarding = lazyLoading(() => import('@/components/boarding/page'))
const SiteRoot = lazyLoading(() => import('@/components/site_root'))
const BillingDownloadProformaInvoice = lazyLoading(() => import('@/pages/app/billing/billing_download_proforma_invoice'))
const BillingOverview = lazyLoading(() => import('@/pages/app/billing/billing_overview'))
const BillingPayment = lazyLoading(() => import('@/pages/app/billing/billing_payment'))
const AccountActivity = lazyLoading(() => import('@/pages/app/billing/account_activity'))
const PersonalInformation = lazyLoading(() => import('@/components/PersonalInformation'))
const OrganizationInformation = lazyLoading(() => import('@/components/OrganizationInformation'))
const Layout = lazyLoading(() => import("@/Layout"))
const NotificationsList = lazyLoading(() => import("@/components/notifications-list"))
const TermOfUse = lazyLoading(() => import("@/components/TermOfUse"))
const PrivacyPolicy = lazyLoading(() => import("@/components/PrivacyPolicy"))
const DataProcessingAgreement = lazyLoading(() => import("@/components/DataProcessingAgreement"))
const AppTypeHelp = lazyLoading(() => import("@/components/appType-help"))
const ReportsSites = lazyLoading(() => import("@/pages/app/reports/projects/projects"))
const ReportsApplications = lazyLoading(() => import("@/components/reports-applications"))
const ReportsDockers = lazyLoading(() => import("@/pages/app/reports/dockers/reports-dockers"))
const ReportsErrors = lazyLoading(() => import("@/components/reports-errors"))

//Admin
const AdminThirdPartyUsageList = lazyLoading(() => import('@/components/admin/thirdpartyusage/list.vue'))
const PaymentsReport = lazyLoading(() => import('@/components/admin/reports/payments'))
const DiscountsReport = lazyLoading(() => import('@/components/admin/reports/discounts'))
const AdminOrganizations = lazyLoading(() => import('@/components/admin/organizations'))
const AdminProformaInvoices = lazyLoading(() => import('@/components/admin/proforma_invoice/proforma_invoices'))
const AdminDistributors = lazyLoading(() => import('@/pages/distributors/adminDistributors/distributors'))
const AdminOnboardingStepper = lazyLoading(() => import('@/pages/onboarding-stepper/OnboardingStepperAdmin.vue'))
const AdminServiceCategories = lazyLoading(() => import('@/pages/service-categories/service-categories.vue'))

const AdminDistributorsWithdrawals = lazyLoading(() => import('@/pages/distributors/adminDistributors/distributorsWithdrawals'))
const AdminOrganizationDetails = lazyLoading(() => import('@/components/admin/organization_details'))
const AdminSiteDetails = lazyLoading(() => import('@/components/admin/admin_organization_details_tabs/site_details'))
const AdminApiKeyDetails = lazyLoading(() => import('@/components/admin/admin_organization_details_tabs/api_keys_details'))
const AdminApiKeyReport = lazyLoading(() => import('@/components/admin/admin_organization_details_tabs/api_keys_report'))
const AdminDockerDetails = lazyLoading(() => import('@/components/admin/admin_organization_details_tabs/docker_details'))
const Roles = lazyLoading(() => import('@/components/admin/settings_tabs/roles'))
const Settings = lazyLoading(() => import('@/components/admin/settings_tabs/settings'))
const ApplicationType = lazyLoading(() => import("@/components/admin/settings_tabs/application_type"))
const ApplicationTypeEdit = lazyLoading(() => import("@/components/admin/settings_tabs/application_type_edit"))
const ManualPaymentTypes = lazyLoading(() => import("@/components/admin/settings_tabs/manualPaymentTypes"))
const ManualPaymentTypesEdit = lazyLoading(() => import("@/components/admin/settings_tabs/manualPaymentTypesEdit"))
const ProformaInvoiceTemplate = lazyLoading(() => import("@/components/admin/settings_tabs/proformaInvoiceTemplate"))
const PermissionLevel = lazyLoading(() => import("@/components/admin/settings_tabs/permission_level"))
const AccountTiers = lazyLoading(() => import("@/components/admin/settings_tabs/account_tiers"))

const ApptoneMessageTemplatesRoot = lazyLoading(() => import('@/components/admin/settings_tabs/apptone_message_templates_root'))
const ApptoneMessageTemplates = lazyLoading(() => import("@/components/admin/settings_tabs/apptone_message_templates"))

const Notifications = lazyLoading(() => import('@/components/admin/settings_tabs/notifications'))
const NotificationsRoot = lazyLoading(() => import('@/components/admin/settings_tabs/notifications_root'))
const NotificationsEdit = lazyLoading(() => import('@/components/admin/settings_tabs/notifications_edit'))
const NotificationTypes = lazyLoading(() => import('@/components/admin/settings_tabs/notification_types'))

const ChatGptModels = lazyLoading(() => import('@/components/admin/settings_tabs/chat_gpt_models'))
const SttModels = lazyLoading(() => import('@/components/admin/settings_tabs/stt_models'))
const EmailAutomation = lazyLoading(() => import('@/components/admin/settings_tabs/email_automation'))

const SystemTextRoot = lazyLoading(() => import('@/components/admin/settings_tabs/system_text_root'))
const SystemText = lazyLoading(() => import('@/components/admin/settings_tabs/system_text'))
const SystemTextEdit = lazyLoading(() => import('@/components/admin/settings_tabs/system_text_edit'))
const SystemTextCreate = lazyLoading(() => import('@/components/admin/settings_tabs/system_text_create'))

const DocumentationRoot = lazyLoading(() => import('@/components/admin/documentation/documentation_root'))
const Documentation = lazyLoading(() => import('@/components/admin/documentation/documentation'))
const DocumentationEdit = lazyLoading(() => import('@/components/admin/documentation/documentation_edit'))
const DocumentationCreate = lazyLoading(() => import('@/components/admin/documentation/documentation_create'))
const DocumentationImages = lazyLoading(() => import('@/components/admin/documentation/images'))

const Translation = lazyLoading(() => import("@/components/admin/translation/translation.vue"))

const DockerVersions = lazyLoading(() => import("@/components/admin/settings_tabs/docker_versions"))
const DockerVersionsCreate = lazyLoading(() => import("@/components/admin/settings_tabs/docker_versions_add"))
const DockerVersionsEdit = lazyLoading(() => import("@/components/admin/settings_tabs/docker_versions_edit"))
const DockerVersionsRoot = lazyLoading(() => import("@/components/admin/settings_tabs/docker_versions_root"))

const AffiliateCouponType = lazyLoading(() => import("@/components/admin/settings_tabs/affiliate_coupon_types"))

const LicenseKeys = lazyLoading(() => import('@/components/admin/license_keys/license_keys'))
const CreditKeys = lazyLoading(() => import('@/components/admin/credit_keys/credit_keys'))
const ReconfigurationKeys = lazyLoading(() => import('@/components/admin/reconfiguration_keys/reconfiguration_keys'))
const AdminDockerRegressionTest = lazyLoading(() => import('@/components/admin/docker-test/list.vue'))
const AdminDockerRegressionTestsEdit = lazyLoading(() => import('@/components/admin/docker-test/edit.vue'))
const RootKeepAlive = lazyLoading(() => import('@/components/RootKeepAlive.vue'))
const Root = lazyLoading(() => import('@/components/Root.vue'))
const RootOrg = lazyLoading(() => import('@/components/root_org'))
const AdminUploadFiles = lazyLoading(() => import('@/components/admin/research/uploadFiles.vue'))
const AdminProjectsList = lazyLoading(() => import('@/components/admin/research/projects/list.vue'))
const AdminProjectsEdit = lazyLoading(() => import('@/components/admin/research/projects/edit.vue'))
const AdminProjectsFiles = lazyLoading(() => import('@/components/admin/research/projects/files.vue'))
const AdminProjectsStats = lazyLoading(() => import('@/components/admin/research/projects/stats.vue'))
const AdminCustomerSupportDetails = lazyLoading(() => import('@/components/admin/admin_organization_details_tabs/customer_support_case'))

const SystemTips = lazyLoading(() => import("@/components/admin/settings_tabs/system_tips"))
const SystemTipEdit = lazyLoading(() => import("@/components/admin/settings_tabs/system_tip_edit"))
const AppAnalyzeFile = lazyLoading(() => import("@/components/analyzeNow/analyzeFile"))
const AnalyzeNow = lazyLoading(() => import("@/pages/app/analyze-now/main/main.vue"))
const AnalyzeNowEdit = lazyLoading(() => import("@/pages/app/analyze-now/main/analyze-now-edit.vue"))

const FeelGPT = lazyLoading(() => import("@/pages/app/analyze-now/feel-gpt/root.vue"))
const FeelGPTMain = lazyLoading(() => import("@/pages/app/analyze-now/feel-gpt/main.vue"))
const FeelGPTEdit = lazyLoading(() => import("@/pages/app/analyze-now/feel-gpt/editor"))
const FeelGPTResult = lazyLoading(() => import ("@/pages/app/analyze-now/feel-gpt/result"))

const EmailBroadcast = lazyLoading(() => import("@/components/admin/email-broadcast/email-broadcast"))
const DiscoveryReport = lazyLoading(() => import("@/pages/app/analyze-now/discovery-report/Layout.vue"))
const DiscoveryReportMain = lazyLoading(() => import("@/pages/app/analyze-now/discovery-report/main"))
const DiscoveryReportResult = lazyLoading(() => import("@/pages/app/analyze-now/discovery-report/report"))

const DiscoveryReportTicketEdit = lazyLoading(() => import("@/pages/app/analyze-now/discovery-report/discovery-report-ticket-edit.vue"))

const EmotionalDiamond = lazyLoading(() => import("@/pages/app/analyze-now/emotional-diamond/Layout"))
const EmotionalDiamondMain = lazyLoading(() => import("@/pages/app/analyze-now/emotional-diamond/main"))
const EmotionalDiamondResult = lazyLoading(() => import("@/pages/app/analyze-now/emotional-diamond/diamond-result.vue"))
const EmotionalDiamondTicketEdit = lazyLoading(() => import("@/pages/app/analyze-now/emotional-diamond/diamond-ticket-edit.vue"))

const ApptoneQuestionnaires = lazyLoading(() => import("@/pages/app/apptone/questionnaires"))
const ApptoneQuestionnaireEdit = lazyLoading(() => import("@/pages/app/apptone/questionnaires-edit/questionnaire-edit"))

const ApptoneQuestionnaireView = lazyLoading(() => import("@/pages/app/apptone/questionnaire-view/questionnaire-view.vue"))
const ApptoneQuestionnaireAnalysisReport = lazyLoading(() => import("@/pages/app/apptone/analysis-report/report"))

const ApptoneCampaigns = lazyLoading(() => import("@/pages/app/apptone/campaigns/campaigns"))
const ApptoneCampaignEdit = lazyLoading(() => import("@/pages/app/apptone/campaigns/campaign-edit"))
const ApptoneCampaignErrors = lazyLoading(() => import("@/pages/app/apptone/campaigns/errors"))


const DistributorsRoot = lazyLoading(() => import('@/pages/distributors/Root.vue'))
const DistributorsDashboard = lazyLoading(() => import('@/pages/distributors/dashboard/dashboard.vue'))
const DistributorsCommissionsManagement = lazyLoading(() => import('@/pages/distributors/commissionManagement/commissionManagement.vue'))
const DistributorsBankDetails = lazyLoading(() => import('@/pages/distributors/bankDetails/bankDetails.vue'))
const DistributorsLinkAccount = lazyLoading(() => import('@/pages/distributors/linkAccount/linkAccount.vue'))

const WebApptoneLayout = lazyLoading(() => import("@/pages/web/apptone/Layout"))
const WebApptone = lazyLoading(() => import("@/pages/web/apptone/default/Layout"))
const WebApptoneMitsui = lazyLoading(() => import("@/pages/web/apptone/mitsui/Layout"))

const PrintPdfLayout = lazyLoading(() => import("@/components/pdf/Layout.vue"))
const KeepAliveLayout = lazyLoading(() => import("@/components/admin/KeepAliveLayout.vue"));

export default {
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
        if (to.meta?.preserveScroll) {
            if (to.path === from.path) {
                return savedPosition;
            }
        }
        // always scroll to top
        const appContainer = document.getElementById('app-container');
        if (appContainer)
            appContainer.scrollTop = 0;
        return {top: 0}
    },
    routes: [
        {
            path: '/register/verify',
            name: ROUTES.Verify,
            component: Verify
        },
        {
            path: '/create-password',
            name: 'CreatePassword',
            component: CreatePassword,
            props: true,
            meta: {permissions: {}}
        },
        {
            path: '/login',
            name: ROUTES.Login,
            component: Login,
        },
        {
            path: '/unsubscribe/:code',
            name: ROUTES.Unsubscribe,
            component: Unsubscribe,
        },
        {
            path: '/2fa',
            name: ROUTES.Login2FA,
            component: Login2FA,
        },
        {
            path: '/email-confirmation',
            name: ROUTES.EmailConfirmation,
            component: EmailConfirmation,
            props: true
        },
        {
            path: '/onboarding-steps',
            name: ROUTES.OrgOnboardingSteps,
            component: OrgOnboardingSteps,
        },
        {
            path: '/register',
            name: ROUTES.Register,
            component: Register
        },
        {
            path: '/reset-password',
            name: ROUTES.ResetPassword,
            component: ResetPassword
        },
        {
            path: '/forgot-password',
            name: ROUTES.ForgotPassword,
            component: ForgotPassword
        },
        {
            path: '/reactivate-account',
            name: ROUTES.ReactivateAccount,
            component: ReactivateAccount
        },
        {
            path: '/payment-success',
            name: ROUTES.PaymentSuccess,
            component: PaymentSuccess
        },
        {
            path: '/payment-error',
            name: ROUTES.PaymentError,
            component: PaymentError
        },
        {
            path: '/term-of-use',
            name: ROUTES.TermOfUse,
            component: TermOfUse,
            meta: {permissions: {}}
        },
        {
            path: '/documentation/:documentationCode?',
            name: ROUTES.DocumentationPage,
            component: DocumentationPage,
            meta: {permissions: {}}
        },
        {
            path: '/privacy-policy',
            name: ROUTES.PrivacyPolicy,
            component: PrivacyPolicy,
            meta: {permissions: {}}
        },
        {
            path: '/data-processing-agreement',
            name: ROUTES.DataProcessingAgreement,
            component: DataProcessingAgreement,
            meta: {permissions: {}}
        },
        {
            path: '/print-pdf',
            name: ROUTES.PrintPdfLayout,
            component: PrintPdfLayout,
            meta: {permissions: {}}
        },
        {
            path: '/l/:shortLinkId',
            name: ROUTES.ShortLink,
            component: ShortLink,
        },
        {
            path: '/apptone/mitsui/:organizationExternalId/:questionnaireExternalId/:apptoneCampaignExternalId?',
            name: 'web-apptone-mitsui',
            component: WebApptoneLayout,
            meta: {},
            children: [
                {
                    path: '',
                    name: ROUTES.WebApptoneMitsui,
                    component: WebApptoneMitsui,
                    meta: {permissions: {}},
                },
            ]
        },
        {
            path: '/apptone/:organizationExternalId/:questionnaireExternalId/:apptoneCampaignExternalId?',
            name: 'web-apptone',
            component: WebApptoneLayout,
            meta: {},
            children: [
                {
                    path: '',
                    name: ROUTES.WebApptone,
                    component: WebApptoneMitsui,
                    meta: {permissions: {}},
                },
            ]
        },
        {
            path: '',
            name: 'base',
            component: Layout,
            redirect: '/analyzeNow',
            meta: {emailVerified: true},
            children: [
                {
                    path: '/dashboard',
                    name: ROUTES.Dashboard,
                    component: Dashboard,
                    meta: {permissions: {billing_payment_read: true}},
                },

                {
                    path: '/analyzeFile',
                    name: ROUTES.AppAnalyzeFile,
                    component: AppAnalyzeFile,
                    meta: {permissions: {analyze_file_app_usage: true/*, analyze_file_app_management: true*/}}
                },

                {
                    path: '/analyzeNow/',
                    name: ROUTES.AnalyzeNowRoot,
                    component: Root,
                    meta: {permissions: {}},
                    children: [{
                        path: '',
                        name: ROUTES.AnalyzeNow,
                        component: AnalyzeNow,
                        meta: {allowMobile: true, permissions: {}}
                    },{
                        path: ':id',
                        name: ROUTES.AnalyzeNowEdit,
                        component: AnalyzeNowEdit,
                        meta: {permissions: {analyze_now_apps_management: true}}
                    }]
                },

                {
                    path: '/feel-gpt/',
                    name: ROUTES.FeelGPTRoot,
                    component: Root,
                    meta: {permissions: {feelgpt_app_usage: true}},
                    children: [{
                        name: ROUTES.FeelGPT,
                        path: '',
                        component: FeelGPT,
                        meta: {allowMobile: true, permissions: {feelgpt_app_usage: true}}
                    }, {
                        name: ROUTES.FeelGPTMain,
                        path: ':id',
                        component: FeelGPTMain,
                        meta: {allowMobile: true, permissions: {feelgpt_app_usage: true}}
                    }, {
                        path: 'edit/:id',
                        name: ROUTES.FeelGPTEdit,
                        component: FeelGPTEdit,
                        meta: {permissions: {feelgpt_app_usage: true, feelgpt_advisor_edit: true}}
                    }, {
                        path: ':id/result/:reportId',
                        name: ROUTES.FeelGPTResult,
                        component: FeelGPTResult,
                        meta: {allowMobile: true, permissions: {feelgpt_app_usage: true}}
                    }, {
                        path: ':id/result/:reportId/edit',
                        name: ROUTES.FeelGPTResultEdit,
                        component: FeelGPTEdit,
                        meta: {permissions: {feelgpt_app_usage: true, feelgpt_advisor_edit: true}}
                    }]
                },

                {
                    path: '/emotional-diamond/',
                    name: ROUTES.EmotionalDiamondRoot,
                    component: RootKeepAlive,
                    meta: {permissions: {emotional_diamond_app: true}},
                    children: [
                        {
                            path: '',
                            name: ROUTES.EmotionalDiamond,
                            component: EmotionalDiamond,
                            meta: {allowMobile: true, permissions: {emotional_diamond_app: true}}
                        },
                        {
                            path: ':id',
                            name: ROUTES.EmotionalDiamondMain,
                            component: EmotionalDiamondMain,
                            meta: {allowMobile: true, permissions: {emotional_diamond_app: true}}
                        },
                        {
                            path: ':id/result',
                            name: ROUTES.EmotionalDiamondResult,
                            component: EmotionalDiamondResult,
                            meta: {allowMobile: true, permissions: {emotional_diamond_app: true}},
                            props: true
                        }, {
                            path: 'edit/:id',
                            name: ROUTES.EmotionalDiamondTicketEdit,
                            component: EmotionalDiamondTicketEdit,
                            meta: {permissions: {emotional_diamond_app: true, emotional_diamond_app_management: true}}
                        }
                    ]
                },

                {
                    path: '/discovery-report/',
                    name: ROUTES.DiscoveryReportRoot,
                    component: Root,
                    meta: {permissions: {discovery_report_app: true}},
                    children: [
                        {
                            path: '',
                            name: ROUTES.DiscoveryReport,
                            component: DiscoveryReport,
                            meta: {allowMobile: true, permissions: {discovery_report_app: true}}
                        },
                        {
                            path: ':id',
                            name: ROUTES.DiscoveryReportMain,
                            component: DiscoveryReportMain,
                            meta: {allowMobile: true, permissions: {discovery_report_app: true}}
                        },
                        {
                            path: ':id/result',
                            name: ROUTES.DiscoveryReportResult,
                            component: DiscoveryReportResult,
                            meta: {allowMobile: true, permissions: {discovery_report_app: true}},
                        }, {
                            path: 'edit/:id',
                            name: ROUTES.DiscoveryReportTicketEdit,
                            component: DiscoveryReportTicketEdit,
                            meta: {permissions: {discovery_report_app: true, discovery_report_app_management: true}}
                        }
                    ]
                },
                {
                    path: '/apptone/questionnaires/',
                    name: ROUTES.ApptoneQuestionnairesRoot,
                    component: Root,
                    meta: {permissions: {apptone_read: true}, preserveScroll: true},
                    children: [{
                        path: '',
                        name: ROUTES.ApptoneQuestionnaires,
                        component: ApptoneQuestionnaires,
                        meta: {allowMobile: false, permissions: {apptone_read: true}}
                    },{
                        path: 'view/:id',
                        name: ROUTES.ApptoneQuestionnaireView,
                        component: ApptoneQuestionnaireView,
                        props: true,
                        meta: {allowMobile: false, permissions: {apptone_read: true}}
                    },{
                        path: ':id',
                        name: ROUTES.ApptoneQuestionnaireEdit,
                        component: ApptoneQuestionnaireEdit,
                        props: true,
                        meta: {permissions: {apptone_read: true}}
                    }, {
                        path: 'analysis-report/:reportId',
                        name: ROUTES.ApptoneQuestionnaireAnalysisReport,
                        component: ApptoneQuestionnaireAnalysisReport,
                        props: true,
                        meta: {allowMobile: false, permissions: {apptone_read: true}},
                    }]
                },
                {
                    path: '/apptone/campaigns/',
                    name: ROUTES.ApptoneCampaignsRoot,
                    component: Root,
                    meta: {allowMobile: false, permissions: {apptone_campaign_read: true}},
                    children: [{
                        path: '',
                        name: ROUTES.ApptoneCampaigns,
                        component: ApptoneCampaigns,
                        meta: {permissions: {apptone_campaign_read: true}}
                    },{
                        path: 'errors',
                        name: ROUTES.ApptoneCampaignsErrors,
                        component: ApptoneCampaignErrors,
                        meta: {permissions: {apptone_campaign_read: true}}
                    },{
                        path: ':id',
                        name: ROUTES.ApptoneCampaignEdit,
                        component: ApptoneCampaignEdit,
                        props: true,
                        meta: {permissions: {apptone_campaign_read: true}}
                    }]
                },
                {
                    path: '/projects',
                    name: ROUTES.Projects,
                    component: Projects,
                    meta: {permissions: {}},
                },
                {
                    path: '/reports-sites',
                    name: `${ROUTES.ReportsSites}_root`,
                    component: SiteRoot,
                    meta: {permissions: {}},
                    children: [{
                        path: '',
                        name: ROUTES.ReportsSites,
                        meta: {allowMobile: true, permissions: {sites_read: true}},
                        component: ReportsSites,
                    }, {
                        path: ':siteExternalId',
                        name: ROUTES.ReportSiteDetails,
                        component: SiteDetails,
                        meta: {
                            permissions: {},
                            onBreadcrumbs: updateSiteReportBreadcrumbs,
                        }
                    },
                        {
                            path: ':siteExternalId/apiKey/:apiKeyExternalId',
                            name: ROUTES.ReportApiKeyDetails,
                            component: ApiKeyDetails,
                            meta: {
                                permissions: {apikeys_edit: true},
                                onBreadcrumbs: updateSiteReportBreadcrumbs,
                            }
                        },
                        {
                            path: ':siteExternalId/apiKey/:apiKeyExternalId/docker/:dockerExternalId',
                            name: ROUTES.ReportDockerDetails,
                            component: DockerDetails,
                            meta: {
                                permissions: {apikeys_edit: true},
                                onBreadcrumbs: updateSiteReportBreadcrumbs,
                            }
                        },]
                },
                {
                    path: '/reports-applications',
                    name: ROUTES.ReportsApplications,
                    component: ReportsApplications,
                    meta: {allowMobile: true, permissions: {sites_read: true}},
                },
                {
                    path: '/reports-dockers',
                    name: ROUTES.ReportsDockers,
                    component: ReportsDockers,
                    meta: {permissions: {sites_read: true}},
                },
                {
                    path: '/reports-errors',
                    name: ROUTES.ReportsErrors,
                    component: ReportsErrors,
                    meta: {permissions: {sites_read: true}},
                },
                {
                    path: '/appTypeHelp/:plugName',
                    name: ROUTES.AppTypeHelp,
                    component: AppTypeHelp,
                    props: true,
                    meta: {permissions: {sites_read: true}},
                },

                {
                    path: '/billing/download-proforma-invoice/:proformaInvoiceExternalId',
                    name: ROUTES.BillingDownloadProformaInvoice,
                    component: BillingDownloadProformaInvoice,
                    meta: {allowMobile: true, permissions: {}}
                },
                {
                    path: '/billing/overview',
                    name: ROUTES.BillingOverview,
                    component: BillingOverview,
                    meta: {allowMobile: true, permissions: {billing_payment_read: true}}
                },
                {
                    path: '/billing/payment',
                    name: ROUTES.BillingPayment,
                    component: BillingPayment,
                    meta: {allowMobile: true, permissions: {billing_payment_edit: true, billing_payment_read: true}}
                },
                {
                    path: '/billing/accountActivity',
                    name: ROUTES.AccountActivity,
                    component: AccountActivity,
                    //meta: {allowMobile: true, permissions: {billing_account_activity: true}}
                    meta: {allowMobile: true, permissions: {billing_payment_edit: true}}
                },
                {
                    path: '/personal-info',
                    name: ROUTES.PersonalInformation,
                    component: PersonalInformation,
                    meta: {allowMobile: true, permissions: {}}
                },
                {
                    path: '/organization-info',
                    name: ROUTES.OrganizationInformation,
                    component: OrganizationInformation,
                    meta: {allowMobile: true, permissions: {}}
                },
                {
                    path: '/settings/users',
                    name: ROUTES.SettingsUsers,
                    component: SettingsUsers,
                    meta: {allowMobile: true, permissions: {user_read: true}}
                },
                {
                    path: '/settings/api-keys',
                    name: ROUTES.AnalyzeNowAPIKeys,
                    component: AnalyzeNowAPIKeys,
                    meta: {allowMobile: true, permissions: {user_read: true}}
                },
                {
                    path: '/account/security',
                    name: ROUTES.AccountSecurity,
                    component: AccountSecurity,
                    meta: {permissions: {user_read: true}}
                },

                {
                    path: '/settings/site/',
                    name: "site_root",
                    component: SiteRoot,
                    meta: {permissions: {}},
                    children: [
                        {
                            path: 'new',
                            name: ROUTES.Onboarding,
                            component: Onboarding,
                            meta: {permissions: {sites_edit: true, apikeys_edit: true}}
                        },
                        {
                            path: ':siteExternalId',
                            name: ROUTES.SiteDetails,
                            component: SiteDetails,
                            meta: {permissions: {}}
                        },
                        {
                            path: ':siteExternalId/apiKey/:apiKeyExternalId',
                            name: ROUTES.ApiKeyDetails,
                            component: ApiKeyDetails,
                            meta: {permissions: {apikeys_read: true}}
                        },
                        {
                            path: ':siteExternalId/apiKey/:apiKeyExternalId/docker/:dockerExternalId',
                            name: ROUTES.DockerDetails,
                            component: DockerDetails,
                            meta: {permissions: {apikeys_read: true}}
                        },
                    ]
                },

                {
                    path: '/cloudAnalysis',
                    name: ROUTES.CloudAnalysis,
                    component: CloudAnalysis,
                    meta: {permissions: {cloud_analysis_history_read: true}}
                },
                {
                    path: '/docker-errors',
                    name: ROUTES.DockerErrors,
                    component: DockerErrors,
                    meta: {}
                },
                {
                    path: '/settings/notifications',
                    name: ROUTES.SettingsNotifications,
                    component: SettingsNotifications,
                    meta: {allowMobile: true, permissions: {notifications_settings_read: true}}
                },
                {
                    path: '/settings/security',
                    name: ROUTES.SettingsSecurity,
                    component: SettingsSecurity,
                    meta: {permissions: {}}
                },
                {
                    path: '/notifications',
                    name: ROUTES.NotificationList,
                    component: NotificationsList,
                    meta: {permissions: {notifications_read: true}, allowMobile: true},
                    props: true
                },
                {
                    path: '/customer-support',
                    name: ROUTES.CustomerSupportCasesRoot,
                    component: CustomerSupportCasesRoot,
                    meta: {allowMobile: true},
                    children: [
                        {
                            path: '',
                            name: ROUTES.CustomerSupportCases,
                            component: CustomerSupportCases,
                            meta: {permissions: {}},
                            props: true
                        },
                        {
                            path: ':csSupportRequestExternalId',
                            name: ROUTES.CustomerSupportCaseManage,
                            component: CustomerSupportCaseManage,
                            meta: {permissions: {}},
                            props: true
                        }
                    ]
                },
                {
                    path: '/customer-support-management',
                    name: ROUTES.CustomerSupportCasesManagementRoot,
                    component: CustomerSupportCasesManagementRoot,
                    meta: {allowMobile: true},
                    children: [
                        {
                            path: '',
                            name: ROUTES.CustomerSupportCasesManagement,
                            component: CustomerSupportCasesManagement,
                            meta: {permissions: {cs_manage_write: true}},
                            props: true
                        },
                        {
                            path: ':csSupportRequestExternalId',
                            name: ROUTES.CustomerSupportCaseManagement,
                            component: CustomerSupportCaseManageManagement,
                            meta: {permissions: {cs_manage_write: true}},
                            props: true
                        }
                    ]
                },
                {
                    path: '/distributors',
                    name: ROUTES.DistributorsRoot,
                    component: DistributorsRoot,
                    redirect: '/distributors/dashboard',
                    meta: {permissions: {distributors_organization_admin: true}},
                    children: [
                        {
                            path: 'link-account',
                            name: ROUTES.DistributorsLinkAccount,
                            component: DistributorsLinkAccount,
                            meta: {permissions: {distributors_organization_admin: true}},
                        },
                        {
                            path: 'dashboard',
                            name: ROUTES.DistributorsDashboard,
                            component: DistributorsDashboard,
                            meta: {permissions: {distributors_organization_admin: true}},
                        },
                        {
                            path: 'commissions',
                            name: ROUTES.DistributorsCommissionsManagement,
                            component: DistributorsCommissionsManagement,
                            meta: {permissions: {distributors_organization_admin: true}},
                        },
                        {
                            path: 'bank-details',
                            name: ROUTES.DistributorsBankDetails,
                            component: DistributorsBankDetails,
                            meta: {permissions: {distributors_organization_admin: true}},
                        },
                    ]
                },
                {
                    path: '/customer-support-management',
                    name: ROUTES.CustomerSupportCasesManagementRoot,
                    component: CustomerSupportCasesManagementRoot,
                    meta: {allowMobile: true},
                    children: [
                        {
                            path: '',
                            name: ROUTES.CustomerSupportCasesManagement,
                            component: CustomerSupportCasesManagement,
                            meta: {permissions: {cs_manage_write: true}},
                            props: true
                        },
                        {
                            path: ':csSupportRequestExternalId',
                            name: ROUTES.CustomerSupportCaseManagement,
                            component: CustomerSupportCaseManageManagement,
                            meta: {permissions: {cs_manage_write: true}},
                            props: true
                        }
                    ]
                },
            ]
        },
        {
            path: '',
            name: 'admin',
            component: Layout,
            redirect: '/admin/users',
            meta: {title: '', icon: ''},
            children: [
                {
                    path: '/admin/reports',
                    name: ROUTES.PaymentsReport,
                    component: PaymentsReport,
                    meta: {permissions: {organizations_read: true}},
                },
                {
                    path: '/admin/discounts',
                    name: ROUTES.DiscountsReport,
                    component: DiscountsReport,
                    meta: {permissions: {organizations_read: true}},
                },
                {
                    path: '/admin/organizations',
                    name: ROUTES.AdminOrganizations,
                    component: AdminOrganizations,
                    meta: {permissions: {organizations_read: true}},
                },
                {
                    path: '/admin/thirdpartyusage',
                    name: ROUTES.AdminThirdPartyUsage,
                    component: AdminThirdPartyUsageList,
                    meta: {permissions: {organizations_read: true}},
                },

                {
                    path: '/admin/proforma-invoices',
                    name: ROUTES.AdminProformaInvoices,
                    component: AdminProformaInvoices,
                    meta: {permissions: {admin_site_access: true}},
                },

                {
                    path: '/admin/distributors',
                    name: ROUTES.AdminDistributors,
                    component: AdminDistributors,
                    meta: {permissions: {distributors_system_admin: true}},
                },
                {
                    path: '/admin/onboarding-stepper',
                    name: ROUTES.AdminOnboardingStepper,
                    component: AdminOnboardingStepper,
                    meta: {permissions: {admin_site_access: true}},
                },
                {
                    path: '/admin/service-categories',
                    name: ROUTES.AdminServiceCategories,
                    component: AdminServiceCategories,
                    meta: {permissions: {admin_site_access: true}},
                },
                {
                    path: '/admin/distributors-withdrawals',
                    name: ROUTES.AdminDistributorsWithdrawals,
                    component: AdminDistributorsWithdrawals,
                    meta: {permissions: {distributors_system_admin: true}},
                },

                {
                    path: '/admin/email-broadcast',
                    name: ROUTES.AdminEmailBroadcast,
                    component: EmailBroadcast,
                    meta: {permissions: {organizations_read: true}},
                },

                {
                    path: '/admin/organization/:organizationExternalId',
                    name: ROUTES.AdminOrganizationDetails + '_root',
                    component: CustomerSupportCasesManagementRoot,
                    children: [ {
                        path: '',
                        name: ROUTES.AdminOrganizationDetails,
                        component: AdminOrganizationDetails,
                        props: true,
                    }, {
                        path: 'customer-support/:csSupportRequestExternalId',
                        name: ROUTES.AdminCustomerSupportDetails,
                        component: AdminCustomerSupportDetails,
                        props: true
                    }, ]
                },

                {
                    component: RootOrg,
                    path: '/admin/organization/',
                    children: [
                        {
                            path: ':organizationExternalId/site/:siteExternalId',
                            name: ROUTES.AdminSiteDetails,
                            component: AdminSiteDetails,
                        },
                        {
                            path: ':organizationExternalId/site/:siteExternalId/apiKey/:apiKeyExternalId',
                            name: ROUTES.AdminApiKeyDetails,
                            component: AdminApiKeyDetails,
                        },
                        {
                            path: ':organizationExternalId/reports-applications',
                            name: ROUTES.AdminApiKeyReport,
                            component: AdminApiKeyReport,
                        },
                        {
                            path: ':organizationExternalId/site/:siteExternalId/apiKey/:apiKeyExternalId/docker/:dockerExternalId',
                            name: ROUTES.AdminDockerDetails,
                            component: AdminDockerDetails,
                        },
                    ]
                },




                {
                    path: '/admin/settings/roles',
                    name: ROUTES.MANAGEMENT.ROLES,
                    component: Roles,
                    meta: {permissions: {roles_read: true}},
                },
                {
                    path: '/admin/settings/settings',
                    name: ROUTES.MANAGEMENT.SETTINGS,
                    component: Settings,
                    meta: {permissions: {settings_read: true}},
                },
                {
                    path: '/admin/settings/account-tiers',
                    name: ROUTES.MANAGEMENT.ACCOUNT_TIERS.LIST,
                    component: AccountTiers,
                    meta: {permissions: {account_tier_read: true}},
                },
                {
                    path: '/admin/settings/application-types',
                    name: ROUTES.MANAGEMENT.APPLICATION_TYPES.LIST,
                    component: ApplicationType,
                    meta: {permissions: {application_types_read: true}},
                },
                {
                    path: '/admin/settings/application-types/:applicationTypeExternalId',
                    name: ROUTES.MANAGEMENT.APPLICATION_TYPES.EDIT,
                    component: ApplicationTypeEdit,
                },
                {
                    path: '/admin/settings/manual-payment-types',
                    name: ROUTES.MANAGEMENT.MANUAL_PAYMENT_TYPES.LIST,
                    component: ManualPaymentTypes,
                    meta: {permissions: {manual_payment_types_read: true}},
                },
                {
                    path: '/admin/settings/manual-payment-types/:manualPaymentTypeExternalId',
                    name: ROUTES.MANAGEMENT.MANUAL_PAYMENT_TYPES.EDIT,
                    component: ManualPaymentTypesEdit,
                },
                {
                    path: '/admin/settings/pi-template',
                    name: ROUTES.MANAGEMENT.PI_TEMPLATE,
                    component: ProformaInvoiceTemplate,
                    meta: {permissions: {admin_site_access: true}},
                },
                {
                    path: '/admin/settings/permission-levels',
                    name: ROUTES.MANAGEMENT.PERMISSION_LEVELS,
                    component: PermissionLevel,
                    meta: {permissions: {permission_levels_read: true}},
                },
                {
                    path: '/admin/notification_templates',
                    name: ROUTES.NotificationTemplatesRoot,
                    component: NotificationsRoot,
                    children: [
                        {
                            path: '',
                            name: ROUTES.MANAGEMENT.NOTIFICATION_TEMPLATES.LIST,
                            component: Notifications,
                            meta: {
                                children: {
                                    [ROUTES.MANAGEMENT.NOTIFICATION_TEMPLATES.EDIT]: true
                                },
                                permissions: {notification_templates_read: true},
                            },
                        },
                        {
                            path: ':externalId',
                            name: ROUTES.MANAGEMENT.NOTIFICATION_TEMPLATES.EDIT,
                            component: NotificationsEdit,
                        },
                    ]
                },
                {
                    path: '/admin/APPTONE_MESSAGE_TEMPLATES',
                    name: ROUTES.ApptoneMessageTemplatesRoot,
                    component: ApptoneMessageTemplatesRoot,
                    children: [
                        {
                            path: '',
                            name: ROUTES.MANAGEMENT.APPTONE_MESSAGE_TEMPLATES.LIST,
                            component: ApptoneMessageTemplates,
                            meta: {
                                children: {
                                    [ROUTES.MANAGEMENT.APPTONE_MESSAGE_TEMPLATES.EDIT]: true
                                },
                                permissions: {apptone_message_template_read: true},
                            },
                        },
                        // {
                        //     path: ':externalId',
                        //     name: ROUTES.MANAGEMENT.NOTIFICATION_TEMPLATES.EDIT,
                        //     component: NotificationsEdit,
                        // },
                    ]
                },
                {
                    path: '/admin/notification_types',
                    name: ROUTES.MANAGEMENT.NOTIFICATION_TYPES,
                    component: NotificationTypes,
                    meta: {permissions: {notification_types_read: true}},
                },
                {
                    path: '/admin/chat_gpt_models',
                    name: ROUTES.MANAGEMENT.CHAT_GPT_MODELS,
                    component: ChatGptModels,
                    meta: {permissions: {chat_gpt_models_read: true}}, //TODO
                },
                {
                    path: '/admin/stt_models',
                    name: ROUTES.MANAGEMENT.STT_MODELS,
                    component: SttModels,
                    meta: {permissions: {stt_models_read: true}},
                },
                {
                    path: '/admin/email_automation',
                    name: ROUTES.MANAGEMENT.EMAIL_AUTOMATION,
                    component: EmailAutomation,
                    meta: {permissions: {email_automation_read: true}},
                },
                {
                    path: '/admin/system_tips',
                    name:  ROUTES.MANAGEMENT.SYSTEM_TIP.LIST + '_root',
                    component: RootKeepAlive,
                    children: [{
                        path: '',
                        name: ROUTES.MANAGEMENT.SYSTEM_TIP.LIST,
                        component: SystemTips,
                        meta: {
                            children: {
                                [ROUTES.MANAGEMENT.SYSTEM_TIP.UPDATE]: true
                            },
                            permissions: {system_text_read: true},
                        },
                    }, {
                            path: ':externalId',
                            name: ROUTES.MANAGEMENT.SYSTEM_TIP.UPDATE,
                            component: SystemTipEdit,
                    },]
                },
                {
                    path: '/admin/system_text',
                    name: ROUTES.SystemGroup,
                    component: SystemTextRoot,
                    children: [
                        {
                            path: '',
                            name: ROUTES.MANAGEMENT.SYSTEM_TEXT.LIST,
                            component: SystemText,
                            meta: {
                                children: {
                                    [ROUTES.MANAGEMENT.SYSTEM_TEXT.CREATE]: true,
                                    [ROUTES.MANAGEMENT.SYSTEM_TEXT.UPDATE]: true
                                },
                                permissions: {system_text_read: true},
                            },
                        },
                        {
                            path: 'create',
                            name: ROUTES.MANAGEMENT.SYSTEM_TEXT.CREATE,
                            component: SystemTextCreate,
                        },
                        {
                            path: ':externalId',
                            name: ROUTES.MANAGEMENT.SYSTEM_TEXT.UPDATE,
                            component: SystemTextEdit,
                        },
                    ]
                },
                {
                    path: '/admin/documentation',
                    name: ROUTES.DocumentationRoot,
                    component: DocumentationRoot,
                    children: [
                        {
                            path: '',
                            name: ROUTES.MANAGEMENT.DOCUMENTATION,
                            component: Documentation,
                            meta: {
                                children: {
                                    [ROUTES.MANAGEMENT.DOCUMENTATION.CREATE]: true,
                                    [ROUTES.MANAGEMENT.DOCUMENTATION.UPDATE]: true,
                                },
                                permissions: {documentation_read: true},
                            },
                            children: [
                                {
                                    path: 'create',
                                    name: ROUTES.MANAGEMENT.DOCUMENTATION.CREATE,
                                    component: DocumentationCreate,
                                },
                                {
                                    path: ':externalId',
                                    name: ROUTES.MANAGEMENT.DOCUMENTATION.UPDATE,
                                    component: DocumentationEdit,
                                },
                            ]
                        }
                    ]
                },
                {
                    path: '/admin/documentation-images',
                    name: ROUTES.DocumentationImages,
                    component: DocumentationImages,
                    meta: {permissions: {documentation_read: true}},
                },
                {
                    path: '/admin/translation',
                    name: ROUTES.TranslationRoot,
                    component: KeepAliveLayout,
                    children: [
                        {
                            path: '',
                            name: ROUTES.MANAGEMENT.TRANSLATION,
                            component: Translation,
                            meta: {
                                permissions: {translations_read: true},
                            },
                        }
                    ]
                },
                {
                    path: '/admin/docker-versions',
                    name: ROUTES.DockerVersionsRoot,
                    component: DockerVersionsRoot,
                    children: [
                        {
                            path: '',
                            name: ROUTES.MANAGEMENT.DOCKER_VERSIONS.LIST,
                            component: DockerVersions,
                            meta: {
                                children: {
                                    [ROUTES.MANAGEMENT.DOCKER_VERSIONS.CREATE]: true,
                                    [ROUTES.MANAGEMENT.DOCKER_VERSIONS.UPDATE]: true,
                                },
                                permissions: {docker_versions_read: true},
                            }
                        }, {
                            path: 'create',
                            name: ROUTES.MANAGEMENT.DOCKER_VERSIONS.CREATE,
                            component: DockerVersionsCreate,
                        }, {
                            path: ':externalId',
                            name: ROUTES.MANAGEMENT.DOCKER_VERSIONS.UPDATE,
                            component: DockerVersionsEdit,
                        }
                    ]

                },

                {
                    path: '/admin/settings/coupon-types',
                    name: ROUTES.MANAGEMENT.AFFILIATE_COUPON_TYPE.LIST,
                    component: AffiliateCouponType,
                    meta: {permissions: {manage_coupon_types: true}},
                },

                {
                    path: '/admin/license-keys',
                    name: ROUTES.LicenseKeys,
                    component: LicenseKeys,
                    meta: {permissions: {manual_license_key_read: true}},
                },
                {
                    path: '/admin/credit-keys',
                    name: ROUTES.CreditKeys,
                    component: CreditKeys,
                    meta: {permissions: {manual_license_key_read: true}},
                },
                {
                    path: '/admin/reconfiguration-keys',
                    name: ROUTES.ReconfigurationKeys,
                    component: ReconfigurationKeys,
                    meta: {permissions: {manual_license_key_read: true}},
                },
                {
                    path: '/admin/docker-regression-test',
                    name: ROUTES.AdminDockerRegressionRoot,
                    component: RootKeepAlive,
                    children: [
                        {
                            path: '',
                            name: ROUTES.AdminDockerRegressionTest,
                            component: AdminDockerRegressionTest,
                            meta: {permissions: {docker_regression_test: true}},
                        },
                        {
                            path: ':dockerRegressionTestExternalId',
                            name: ROUTES.AdminDockerRegressionTestsEdit,
                            component: AdminDockerRegressionTestsEdit,
                            meta: {permissions: {docker_regression_test: true}},
                        },
                    ]
                },
                {
                    path: '/admin/research',
                    name: ROUTES.AdminResearchRoot,
                    component: RootKeepAlive,
                    children: [
                        {
                            path: 'projects',
                            name: ROUTES.AdminProjectsRoot,
                            component: RootKeepAlive,
                            children: [
                                {
                                    path: '',
                                    name: ROUTES.AdminProjectsList,
                                    component: AdminProjectsList,
                                    meta: {permissions: {general_research: true}},
                                },
                                {
                                    path: ':researchProjectExternalId/files',
                                    name: ROUTES.AdminProjectsFiles,
                                    component: AdminProjectsFiles,
                                    meta: {permissions: {general_research: true}},
                                },
                                {
                                    path: ':researchProjectExternalId/stats',
                                    name: ROUTES.AdminProjectsStats,
                                    component: AdminProjectsStats,
                                    meta: {permissions: {general_research: true}},
                                },
                                {
                                    path: ':researchProjectExternalId',
                                    name: ROUTES.AdminProjectsEdit,
                                    component: AdminProjectsEdit,
                                    meta: {permissions: {general_research: true}},
                                },
                            ]
                        },
                        {
                            path: 'uploadFiles',
                            name: ROUTES.AdminUploadFiles,
                            component: AdminUploadFiles,
                            meta: {permissions: {general_research: true}},
                        },
                    ]
                },
            ]
        }
    ]
}
