import {createApp, watch} from 'vue';
import App from './App.vue';
import router from './routes';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Popper from "Common/components/controls/popper/Popper";
import Modal from "Common/components/controls/modal";
import Multiselect from '@vueform/multiselect';
import {createPinia} from 'pinia';
import { createGtm } from '@gtm-support/vue-gtm';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
import BootstrapVue3 from 'bootstrap-vue-next';
import {setupI18n} from './i18n';
import messages from './locales/messages.js';
import vue3GoogleLogin from "vue3-google-login";
import dayjs from "dayjs";
import CodeDiff from 'v-code-diff'
import _ from 'lodash'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import {useUserStore} from "Common/stores/userStore";
import {useSystemStore} from "Common/stores/systemStore";
import {getSocket} from "@/socket";
import { environment } from 'Common/environments/environment';
getSocket();

import {checkIfUserAgentIsChrome, checkIfDeviceIsDesktopMac, checkIfDeviceIsMobile} from "@/utility/browserUtils";

import {registerPlayer} from "../public/diamond/nms-lib";
registerPlayer()

const translations = _.merge(messages, window.externalTranslations?.ui || {});


let locale = 'en';
if (location.href.match(/\/print-pdf\?/i)) {
    const localeParam = (location.href.match(/[?&]locale=([^&#]+)/i) || [])[1];
    locale = localeParam || 'en';
}
else if (isLogin(window.location.pathname)) {
    const userLanguage = localStorage.getItem('user-language');
    if (userLanguage) {
        locale = JSON.parse(userLanguage).language || 'en';
    }

}

const i18n = setupI18n({
    globalInjection: true,
    warnHtmlMessage: false, // disable warning HTML in message
    legacy: false,
    locale,
    fallbackLocale: 'en',
    messages: translations
})

const app = createApp(App);
app.use(router);
app.use(CodeDiff);
app.use(createPinia());
if(process.env.VUE_APP_ENABLE_GTM?.toString() === 'true') {
    app.use(
        createGtm({
            id: "GTM-M85TC4SL",
            vueRouter: router
        })
    )
}
app.use(VueTelInput);
app.use(BootstrapVue3);
app.use(i18n);
app.use(vue3GoogleLogin, {
    clientId: process.env.VUE_APP_GOOGLE_AUTH_CLIENT_ID,
});
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';
app.use(VueAxios, axios);

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    if (token)
        config.headers.xtoken = token;

    const us = useUserStore();

    if (us.isLoaded) {
        const organizationExternalId = router.currentRoute.value?.params?.organizationExternalId;

        if (organizationExternalId) {
            config.headers = {
                ...config.headers,
                'x-org-id': organizationExternalId
            }
        }
    }
    return config;
});

axios.interceptors.response.use(
    r => {
        const token = r.headers?.xtoken
        if (token) {
            localStorage.setItem('token', token)
            localStorage.setItem('token-date', Date.now().toString())
        }
        return r
    },
    err => {
        const us = useUserStore();
        if (err.response?.status === 401) {
            us.expired();
        } else if (err.response?.status === 403 && err.response.data.toLoginPage) {
            us.logout();
        } else if (err.response?.data) {
            return err.response;
        }
        return err;
    }
);

setInterval(() => {
    if(window.isPageNotRequireAuth) {
        return
    }
    const tokenDate = localStorage.getItem('token-date');
    const activeDate = localStorage.getItem('user-active-date');
    const logoutAfter= 20;
    const refreshAfter= 5;
    if (tokenDate && activeDate) {
        const now = Date.now();
        const tokenAge = Math.floor((now - tokenDate) / 1000 / 60);
        const idleTime = Math.floor((now - activeDate) / 1000 / 60);
        if(tokenAge > refreshAfter && (idleTime + refreshAfter) < logoutAfter) {
            const us = useUserStore();
            us.getUserProfile().catch(() => {});
        }
        if (tokenAge >= logoutAfter) {
            const us = useUserStore();
            us.expired();
        }
    }

}, 5000)

const setUserOnSite = () => {
    localStorage.setItem('user-active-date', Date.now().toString());
}

const events = ['mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart']
events.forEach((name) => {
    window.addEventListener(name, setUserOnSite, true)
})


app.component('modal', Modal);
app.component("Popper", Popper);
app.component("Multiselect", Multiselect);

app.mixin({
    data() {
        return {};
    },
    methods: {}
});

window.isMac = checkIfDeviceIsDesktopMac();
if (window.isMac && window.innerWidth < 1700) document.body.classList.add('mac-os');
if(checkIfUserAgentIsChrome()) document.body.classList.add('is-chrome');
if(checkIfDeviceIsMobile()) document.body.classList.add('mobile-device');

// RTL
function isLogin(path) {
    return /^\/login\/?$/.test(path)
        || /^\/forgot-password\/?$/.test(path);
}

function addRTLClassIfNeeded(isRTL) {
    if (isRTL && !document.body.classList.contains('rtl')) {
        document.body.classList.add('rtl');
    }
    else if (!isRTL && document.body.classList.contains('rtl')) {
        document.body.classList.remove('rtl');
    }
}

const systemStore = useSystemStore();
if (systemStore.isRTL) document.body.classList.add("rtl");

watch(() => systemStore.isRTL,(newValue, oldValue) =>
    {
      if (newValue !== oldValue) {
          if (!isLogin(window.location.pathname)) {
              addRTLClassIfNeeded(newValue);
          }

      }
    },
    {
        immediate: true
    }
);

router.beforeEach((to, from, next) => {
    if (isLogin(to.path)) {
        const userLanguage = localStorage.getItem('user-language');
        const isRTL = userLanguage ? JSON.parse(userLanguage).isRTL : false;
        addRTLClassIfNeeded(isRTL);
    }

    next();
});

const version = localStorage.getItem('version');
const getServerVersion = async () => {

    let version = ""
    try{
        const r = await axios.get(`/version-check?_=${Date.now()}`)
        version = r.data.version || ""
        console.log('Version from server=', version)

    } catch (e) {
        console.log('Error getting version from server')
    }

    return version.toString()
}
const versionCheck = async () => {
    const serverVersion = await getServerVersion()
    if(serverVersion && serverVersion !== version) {
        localStorage.setItem('version', serverVersion)
        window.location.reload(true)
    }
}

versionCheck()
setInterval(versionCheck, 5 * 60 * 1000)
systemStore.getCaptchaInfo()
.finally(() => {
    app.mount('#app');
});
