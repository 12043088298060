import { defineStore } from 'pinia';
import axios from "axios";
import { environment } from 'Common/environments/environment';
import { useUserStore } from "Common/stores/userStore";
import {i18n} from "@/i18n";
import { Buffer } from 'buffer';
import axiosHelper from 'Common/utility/axiosHelper';

export const useSystemStore = defineStore('systemStore', {
    state: () => {
        return {
            roles: [],
            permissions: [],
            browserInfo: null,
            remoteIp: null,
            systemTexts: [],
            systemTips: [],
            permissionLevels: [],
            textByCode: {},
            appTypeGroups: null,
            translationLanguageList: [],
            isRTL: false,
            isCaptchaEnabled: false,
            getCategoryPromise: null,
        }
    },
    getters: {

    },
    actions: {
        getCaptchaInfo() {
            const that =  this;
            return axios.get(`${environment.systemServiceUrl}/captcha/info`, {
                timeout: 10000
            })
                .then((response) => {
                    if (!response.data.success) return Promise.reject(response.data);
                    const enabled = response.data.data?.enabled || false;
                    that.isCaptchaEnabled = enabled;
                    environment.recaptchaEnabled = enabled;
                    return response.data.data;
                })
        },
        loadAccountTypes() {
            return axios.post(environment.systemServiceUrl + '/accountTypes/search').then(response => {
                if (!response.data.success)
                    throw new Error(response.data.error || "Failed to get account types.");
                return (response.data.data || []);
            });
        },
        getListAccountTypes() {
            return axios.post(environment.systemServiceUrl + '/accountTypes/list').then(response => {
                if (!response.data.success)
                    throw new Error(response.data.error || "Failed to get account types.");
                return (response.data.data || []);
            });
        },
        updateAccountType(data) {
            return axios.post(environment.systemServiceUrl + '/accountTypes/update', { data }).then(response => {
                if (!response.data.success)
                    throw new Error(response.data.error || "Failed to get account types.");
                return (response.data.data || []);
            });
        },

        deleteAppType(data) {
            const {applicationTypeExternalId} = data;
            return axios
              .delete(environment.apiKeysServiceUrl + `/application-types/${applicationTypeExternalId}`)
              .then(response => {
                if (!response.data.success)
                    return Promise.reject(response.data || {error: "Failed to delete app type."})
                return response.data.data;
            });
        },

        loadAppTypesPublic(filter = {}) {
            return axios.post(environment.apiKeysServiceUrl + '/application-types/search-public', {
                data: filter
            }).then(response => {
                if (!response.data.success)
                    throw new Error(response.data.error || "Failed to get application types.");
                return (response.data.data || []);
            });
        },

        loadAppTypes(usageType) {
            return axios.post(environment.apiKeysServiceUrl + '/application-types/search', {
                data: {usageType}
            }).then(response => {
                if (!response.data.success)
                    throw new Error(response.data.error || "Failed to get application types.");
                return (response.data.data || []);
            });
        },


        insertAppType(data) {
            data = {
                ...data,
                temperature: Number(data.temperature)
            }
            return axios.post(environment.apiKeysServiceUrl + '/application-types/', {
                data
            }).then((response) => {
                if (!response.data?.success)
                    return Promise.reject(response.data);

                return response.data.data;
            });
        },

        updateAppType(data) {
            data = {
                ...data,
                temperature: Number(data.temperature)
            }
            return axios.put(environment.apiKeysServiceUrl + `/application-types/${data.applicationTypeExternalId}`, {
                data
            }).then((response) => {
                if (!response.data?.success)
                    return Promise.reject(response.data);
                return response.data.data;
            });
        },

        getAppTypeById(applicationTypeExternalId) {
            return axios
                .get(environment.apiKeysServiceUrl + `/application-types/${applicationTypeExternalId}`)
                .then((response) => {
                    if (!response.data.success) return Promise.reject(response.data);
                    return response.data.data || {};
            });
        },

        getAppTypeGroups() {
            if (this.appTypeGroups)
                return Promise.resolve(this.appTypeGroups);

            const url = `${environment.apiKeysServiceUrl}/application-types/searchGroups`;

            return axios.post(url, {}).then(r => {
                if (!r.data?.success)
                    return Promise.reject(r.data);

                if (r.data.data)
                    this.appTypeGroups = r.data.data;

                return this.appTypeGroups || [];
            });
        },

        loadPermissionLevels(filter) {
            return axios.post(`${environment.systemServiceUrl}/permissionLevels/search`,{
                data:{
                    filter
                }
            })
                .then((response) => {
                    if (!response.data.success) return Promise.reject(response.data);
                    return this.permissionLevels = response.data.data || [];
                })
        },

        updatePermissionLevel(query) {
            return axios.post(`${environment.systemServiceUrl}/permissionLevels/update`,{
                data: query
            })
                .then((response) => {
                    if (!response.data.success) return Promise.reject(response.data);
                    const pl = this.getPermissionLevelById(query.permissionLevelExternalId);
                    Object.assign(pl, query);
                })
        },

        getPermissionLevelById(permissionLevelExternalId) {
            return this.permissionLevels.find((o) => o.permissionLevelExternalId === permissionLevelExternalId);
        },

        loadSystemTexts(data) {
            return axios.post(`${environment.systemServiceUrl}/texts/search`, {data})
                .then((response) => {
                    if (!response.data.success) return Promise.reject(response.data);
                    return this.systemTexts = response.data.data || [];
                })
        },

        loadSystemTextById(textExternalId) {
           return axios.get(`${environment.systemServiceUrl}/texts/${textExternalId}`)
               .then((response) => {
                   if (!response.data.success) return Promise.reject(response.data);
                   return response.data.data;
               })
        },

        loadSystemTextByCode(code, language) {
            const key = `${language}_${code}`;
            const currentText = this.textByCode[key];
            if (currentText)
                return Promise.resolve(currentText);

            const promise = axios.post(`${environment.systemServiceUrl}/texts/getByCode`, {
                data: {
                    code,
                    language
                }
            }).then((response) => {
                if (!response.data?.success) {
                    delete this.textByCode[key];
                    return Promise.reject(response.data);
                }
                return response.data.data;
            });

            this.textByCode[key] = promise;

            return promise;
        },

        loadSystemTextByCodes(codes, language) {
            const {result, missingCodes} = codes.reduce((acc, code) => {
                const key = `${language}_${code}`
                const currentText = this.textByCode[key];
                if (!currentText)
                    acc.missingCodes.push(code);
                else
                    acc.result[code] = currentText;
                return acc;
            }, {result: {}, missingCodes: []});

            const promise = missingCodes.length ? axios.post(`${environment.systemServiceUrl}/texts/getByCodes`, {
                    data: {
                        codes: missingCodes,
                        language
                    }
                }).then(response => {
                    if (!response.data?.success) {
                        missingCodes.forEach(i => {
                            delete this.textByCode[`${language}_${i}`];
                        });
                        return Promise.reject(response.data);
                    }

                    Object.keys(response.data.data).forEach(k => {
                        this.textByCode[`${language}_${k}`] = result[k] = response.data.data[k];
                    });

                    return result;

                }) : Promise.resolve(result);

            return promise.then(r => {
                const subs = Object.keys(r).map(k => Promise.resolve(r[k]).then(v => ({[k]: v})));
                return Promise.all(subs).then(all => all.length ? Object.assign.apply(Object, all) : {});
            });
        },

        deleteSystemText(data) {
            const {textExternalId} = data;
            return axios
              .delete(`${environment.systemServiceUrl}/texts/${textExternalId}`)
              .then((response) => {
                if (!response.data.success) return Promise.reject(response.data);
            });
        },


        updateSystemText(query) {
            return axios.post(`${environment.systemServiceUrl}/texts/update`, {
                data: query
            }).then((response) => {
                if (!response.data.success) return Promise.reject(response.data);
            });
        },

        createSystemText(data) {
            return axios.post(`${environment.systemServiceUrl}/texts/insert`, {
                data
            }).then(response => {
                if (!response.data.success)
                    return Promise.reject(response.data);
            });
        },

        loadRoles() {
            return axios.post(`${environment.systemServiceUrl}/role/search`)
                .then((response) => {
                    if (!response.data.success) return Promise.reject(response.data);
                    return this.roles = response.data.data || [];
                })
        },
        loadPermissions() {
            return axios.post(`${environment.systemServiceUrl}/role/searchPermissions`)
                .then((response) => {
                    if (!response.data.success) return Promise.reject(response.data);
                    return this.permissions = response.data.data || [];
                })
        },
        getSettings() {
            return axios.post(`${environment.systemServiceUrl}/settings/getSettings`)
                .then((response) => {
                    if (!response.data.success) return Promise.reject(response.data);
                    return this.settings = response.data.data || {};
                })
        },
        updateSettings(data) {
            return axios.post(`${environment.systemServiceUrl}/settings/updateSettings`, {data})
                .then((response) => {
                    if (!response.data.success) return Promise.reject(response.data);
                    return this.settings = response.data.data || {};
                })
        },
        insertRole(query) {
            return axios.post(`${environment.systemServiceUrl}/role/insert`, {
                data: query
            }).then((response) => {
                if (!response.data.success) return Promise.reject(response.data);
                this.roles.push(query);
                return response;
            });
        },
        updateRole(query) {
            const that = this
            return axios.post(`${environment.systemServiceUrl}/role/update`, {
                data: query
            }).finally(() => {
                that.loadRoles();
            });
        },
        getRoleById(technicalId) {
            return this.roles.find((o) => o.technicalId === technicalId);
        },

        getBrowserInfo() {
            if (this.browserInfo)
                return Promise.resolve(this.browserInfo);

            return axios.post(`${environment.systemServiceUrl}/client/getBrowserInfo`, {}, {
                headers: {
                    anonymousUserId: 1
                }
            }).then(x => {
                if (!x.data.success)
                    throw new Error(x.data.error || "Failed to get browser info.");
                this.browserInfo = x.data.data;
                return this.browserInfo;
            });
        },

        getRemoteIp() {
            if (this.remoteIp)
                return Promise.resolve(this.remoteIp);

            return axios.post(`${environment.systemServiceUrl}/client/getRemoteIp`, {}, {}).then(x => {
                if (!x.data?.success || !x.data?.data) {
                    throw new Error(x.data.error || "Failed to get browser info.");
                }

                this.remoteIp = x.data.data;
                return this.remoteIp;
            });
        },

        getDockerVersions(filter, params) {
            filter = filter || {};
            params = params || {};
            const req = axios.post(`${environment.apiKeysServiceUrl}/docker-versions/search`, {
                data: {
                    filter,
                    params,
                }
            }, {

            });

            return req.then(r => {

                if (!r.data.success) {
                    return Promise.reject(r.data);
                }

                return r.data.data;
            });

        },

        getDockerVersionById(dockerVersionExternalId) {
            const req = axios.get(`${environment.apiKeysServiceUrl}/docker-versions/${dockerVersionExternalId}`);
            return req.then(r => !r.data.success ? Promise.reject(r.data) : r.data.data);
        },

        updateDockerVersion(data) {
            const req = axios.post(`${environment.apiKeysServiceUrl}/docker-versions/update`, {
                data,
            });
            return req.then(r => {
                if (!r.data.success) {
                    return Promise.reject(r.data);
                }
                return r.data.data
            });
        },

        uploadDockerVersion(file) {
            const url = `${environment.apiKeysServiceUrl}/docker-versions/insert`;
            const fd = new FormData();
            fd.append('file', file)

            return axios.post(url, fd, {headers: { "Content-Type": "multipart/form-data" },}).then(res => {
                if (res.data.success) {
                    return;
                }
                return Promise.reject(res.data)
            });
        },

        loadSystemTipById(systemTipExternalId) {
            return axios.get(`${environment.systemServiceUrl}/tips/${systemTipExternalId}`)
              .then((response) => {
                  if (!response.data?.success)
                      return Promise.reject(response.data);
                  return response.data.data;
              })
        },
        updateSystemTip(data) {
            return axios.post(`${environment.systemServiceUrl}/tips/update`, {
                data
            }).then((response) => {
                if (!response.data?.success)
                    return Promise.reject(response.data);
            });
        },

        insertSystemTip(data) {
            return axios.post(`${environment.systemServiceUrl}/tips/insert`, {
                data
            }).then((response) => {
                if (!response.data?.success)
                    return Promise.reject(response.data);
            });
        },

        loadSystemTips(data, force) {
            if (!force && this.systemTips?.length) {
                return Promise.resolve(this.systemTips);
            }

            return axios.post(`${environment.systemServiceUrl}/tips/search`, {data})
                .then((response) => {
                    if (!response.data?.success)
                        return Promise.reject(response.data);
                    return this.systemTips = response.data.data || [];
                })
        },

        deleteSystemTip(data) {
            const {systemTipExternalId} = data;
            return axios
              .delete(`${environment.systemServiceUrl}/tips/${systemTipExternalId}`)
              .then((response) => {

                  if (!response.data?.success)
                      return Promise.reject(response.data);

                  return response.data;
              });
        },

        getTranslationLanguageList() {
            return axios.post(`${environment.systemServiceUrl}/translations/getAvailableLanguages`)
                .then((response) => {
                    if (!response.data?.success)
                        return Promise.reject(response.data);
                    this.translationLanguageList = response.data.data;
                    this.updateLangDirection();
                    return response.data.data;
                });
        },

        updateLangDirection() {
            const locale = i18n.global.locale.value;

            const language = this.translationLanguageList.find(lang => lang.language === locale);
            if (language) {
                this.isRTL = language.isRTL || false;
                const userLanguage = { language: locale, isRTL: this.isRTL };
                localStorage.setItem('user-language', JSON.stringify(userLanguage));

                //TODO: need to ask Adi to check this as expert in RTL
                if (this.isRTL && !document.body.classList.contains('rtl')) {
                    document.body.classList.add('rtl');
                }
                else if (!this.isRTL && document.body.classList.contains('rtl')) {
                    document.body.classList.remove('rtl');
                }
            }

        },

        getLanguage(data) {
            return axios.post(`${environment.systemServiceUrl}/translations/getByLang`, {
                data
            }).then((response) => {
                if (!response.data?.success)
                    return Promise.reject(response.data);
                return response.data.data;
            });
        },

        addTranslation(data) {
            return axios.post(`${environment.systemServiceUrl}/translations/addTranslation`, {
                data
            }).then((response) => {
                if (!response.data?.success)
                    return Promise.reject(response.data);
                return response.data.data;
            });
        },

        editTranslation(data) {
            return axios.post(`${environment.systemServiceUrl}/translations/editTranslation`, {
                data
            }).then((response) => {
                if (!response.data?.success)
                    return Promise.reject(response.data);
                return response.data.data;
            });
        },

        translate(data) {
            data = {
                ...data,
                messages: data?.messages?.map(msg => Buffer.from(msg).toString('base64')),
                isMessagesEncoded: true
            }
            return axios.post(`${environment.systemServiceUrl}/translations/translate`, {
                data
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                if (!response.data?.success)
                    return Promise.reject(response.data);
                return response.data.data;
            });
        },

        getOriginalByShortLink(shortLinkId, query) {
            return axios.get(`${environment.systemServiceUrl}/short-links/${shortLinkId}?${query}`)
                .then((response) => {
                    if (!response.data?.success)
                        return Promise.reject(response.data);
                    return response.data.data;
                });
        },

        getFeelGptAiModels() {
            return axios.get(`${environment.feelGptServiceUrl}/feel-gpt/ai-models`).then(response => {

                if (!response.data?.success)
                    return Promise.reject(response.data || {errorCode: 'sorrySomethingWrong'});

                return response.data.data;
            });
        },

        getApptoneAiModels() {
            return axios.get(`${environment.apptoneServiceUrl}/apptone/questionnaires/ai-models`).then(response => {

                if (!response.data?.success)
                    return Promise.reject(response.data || {errorCode: 'sorrySomethingWrong'});

                return response.data.data;
            });
        },

        getChatGptModels() {
            return axios.get(`${environment.systemServiceUrl}/chat-gpt/all`).then(response => {

                if (!response.data?.success)
                    return Promise.reject(response.data || {errorCode: 'sorrySomethingWrong'});

                return response.data.data;
            });
        },

        updateChatGptModels(data) {
            return axios.post(`${environment.systemServiceUrl}/chat-gpt/updateMany`, {data}).then(response => {

                if (!response.data?.success)
                    return Promise.reject(response.data || {errorCode: 'sorrySomethingWrong'});

                return response.data.data;
            });
        },

        getSttModels() {
            return axios.get(`${environment.systemServiceUrl}/stt-models/all`).then(response => {

                if (!response.data?.success)
                    return Promise.reject(response.data || {errorCode: 'sorrySomethingWrong'});

                return response.data.data;
            });
        },

        updateSttModels(data) {
            return axios.post(`${environment.systemServiceUrl}/stt-models/updateMany`, {data}).then(response => {

                if (!response.data?.success)
                    return Promise.reject(response.data || {errorCode: 'sorrySomethingWrong'});

                return response.data.data;
            });
        },

        getRedirectSettings() {
            return axios.get(`${environment.systemServiceUrl}/user/redirectSettings`).then(response => {

                if (!response.data?.success)
                    return Promise.reject(response.data || {errorCode: 'sorrySomethingWrong'});

                return response.data.data;
            });
        },

        getServiceCategoriesSettings() {
            if(this.getCategoryPromise) return this.getCategoryPromise;
            else return this.getCategoryPromise = axios.get(`${environment.systemServiceUrl}/service-categories/`).then(response => {

                if (!response.data?.success)
                    return Promise.reject(response.data || {errorCode: 'sorrySomethingWrong'});

                return response.data.data;
            }).finally(() => {
                this.getCategoryPromise = null;
            });
        },

        /**
         * @param {Object} filter
         * @param {boolean} [filter.deleted]
         * @returns {Promise<*>}
         */
        getServiceCategories(filter) {
            const url = `${environment.systemServiceUrl}/service-categories`;
            return axiosHelper.get(url, filter).then(response => {

                if (!response.data?.success)
                    return Promise.reject(response.data || {errorCode: 'sorrySomethingWrong'});

                return response.data.data;
            });
        },

        /**
         * @param {{}[]} data
         * @returns {Promise<*>}
         */
        updateServiceCategories(data) {
            const url = `${environment.systemServiceUrl}/service-categories`;
            return axios.post(url, {data}).then(response => {

                if (!response.data?.success)
                    return Promise.reject(response.data || {errorCode: 'sorrySomethingWrong'});

                return response.data.data;
            });
        },

    }
})
