import axios from "axios";

function capitalizeFirstLetter(val) {
  return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}

function getQueryFromObject(queryParams){
  return Object.keys(queryParams || {})
    .filter(key => {
      const value = queryParams[key];
      if (value === undefined || value === null) {
        return false;
      }

      if (typeof value === 'string' && value.trim() === '') {
        return false;
      }

      if (value instanceof Array && value.length === 0) {
        return false;
      }

      return true;
    })
    .map(key => {

      let value = queryParams[key];

      if (value instanceof Array) {
        value = value.join(',');
      }

      return `${key}=${encodeURIComponent(value)}`
    })
    .join('&');
}

function flattenObject(data){
  const result = {};
  
  for(let key in data){
    if(!data[key]){
      result[key] = data[key];
    }
    else if(Array.isArray(data[key])){
      if(data[key].some(v => !!v && (Array.isArray(v) || typeof v === 'object'))){
        if(key === 'orderBy'){
          result['orderBy'] = data[key].map(o => o.fieldName);
          result['orderDir'] = data[key].map(o => o.rule);
        }
        else{
          console.log(key, data[key]);
          throw Error(`Can't flat array of arrays or array of objects`); 
        }
      }
      else{
        result[key] = data[key];
      }
    }
    else if(typeof data[key] === 'object'){
      const flatObject = flattenObject(data[key]);
      for (let key2 in flatObject) {
        result[key + capitalizeFirstLetter(key2)] = flatObject[key2];
      }
    }
    else{
      result[key] = data[key];
    }
  }
  
  return result;
}

function flatObjectParams(result, data){
  if(!data) return;
  
  const flatObject = flattenObject(data);

  for (let key in flatObject) {
    if(result[key]){
      console.log('result', result);
      console.log('data', data);
      throw Error('A parameter name conflict has occurred');
    }
    
    result[key] = flatObject[key];
  }
}

async function get2(url, data) {
  
  const finalParams = {};
  
  flatObjectParams(finalParams, data);
  //flatObjectParams(finalParams, data.filter);
  //flatObjectParams(finalParams, data.params);
  
  const query = getQueryFromObject(finalParams);
  
  return await axios.get(`${url}?${query}`);
}

/**
 * Sends a GET request using axios with the provided URL and query parameters.
 *
 * @param {string} url - The URL to send the GET request to.
 * @param {Object} queryParams - An object representing the query parameters to include in the request.
 * @returns {Promise} - A Promise that resolves to the response of the GET request.
 */
async function get(url, queryParams, config = {}) {
    const query = getQueryFromObject(queryParams);

    return await axios.get(`${url}?${query}`, config);
}

const axiosHelper = {
    get,
    get2
}

export default axiosHelper;